<template>
  <v-app id="app">
    <!--v-navigation-drawer app>
    </v-navigation-drawer-->
    <v-app-bar app dark>
      <v-toolbar-title>
        <img :src="require('@/assets/logo.svg')" width="130" /> Stats
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex justify-space-between align-center" style="height: 100%;">
        <v-progress-circular
          v-show="loading"
          indeterminate
        ></v-progress-circular>
        <CountryPicker class="mx-5" />
        <ChartPicker />
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <DatePicker />
        <div class="grid gap-16 grid-cols-1 md:grid-cols-2">
          <ActivatedTablets />
          <PairedModules />
        </div>
        <!-- If using vue-router -->
        <!--router-view></router-view-->
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import ActivatedTablets from '@/components/ActivatedTablets'
import PairedModules from '@/components/PairedModules'
import DatePicker from '@/components/DatePicker'
import CountryPicker from '@/components/CountryPicker'
import ChartPicker from '@/components/ChartPicker'

export default {
  name: 'App',
  components: {
    ActivatedTablets, PairedModules, DatePicker, CountryPicker, ChartPicker
  },
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  }
}
</script>

<style>
#app {
  background-color: var(--v-secondary-base) !important;
}
</style>
