<template>
  <v-sheet
    color="white"
    elevation="1"
    class="pa-5 my-10"
  >
    <h1 class="title">
      Paired modules
      <v-tooltip bottom max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-icon slot="activator" color="black"  v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        <span>{{ info }}</span>
      </v-tooltip>
    </h1>
    <div class="py-3">
      <div class="caption mb-3">Total in date range: {{ total }}</div>
      <div class="byCountry" style="max-width: 300px;">
        <v-simple-table height="350" fixed-header dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Country</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(count, countryCode) in byCountryFiltered" :key="countryCode" class="border-2">
                <td :class="{ 'font-bold': countryCode === 'SI' }" class="p-2">{{ countryCode }}</td>
                <td class="p-2">{{ count }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PairedModules',
  data: () => {
    return {
      loading: true,
      errored: false,
      apiUrl: 'https://mcloudstaging.azurewebsites.net/api/dashboard/stats/modules_paired?timestampFrom=1483225200',
      data: {},
      info: 'When the module is paired with the tablet for the first time the tablet sends that information to the cloud which then creates a new entry in the database which contains tablet-module connection data and the date when it was paired. The query for API method takes all tablet-module links which were paired in the specific time period and then counts them grouped by country. MESI and distributor tablets are filtered out the same way as for the Tablets activated method.'
    }
  },
  computed: {
    ...mapState({
      dateFromTimestamp: state => state.dates.from,
      dateToTimestamp: state => state.dates.to,
      selectedCountry: state => state.selectedCountry
    }),
    byCountryFiltered () {
      if (this.selectedCountry === 'all') {
        return this.byCountry
      }

      return Object.keys(this.byCountry)
        .filter(key => this.selectedCountry.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.byCountry[key]
          return obj
        }, {})
    },
    dateFrom () {
      return this.data?.DateFrom
    },
    dateTo () {
      return this.data?.DateTo
    },
    byCountry () {
      return this.data?.ModulesByCountry
    },
    total () {
      return this.data?.TotalForPeriod
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    makeAPIRequest () {
      this.axios
        .get(this.apiUrl)
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => { this.loading = false })
    },
    getData () {
      this.makeAPIRequest()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
