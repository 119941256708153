<template>
  <v-sheet
    color="white"
    elevation="1"
    class="pa-5 my-10"
  >
    <h1 class="title">
      Activated tablets
      <v-tooltip bottom max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-icon slot="activator" color="black"  v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        <span>{{ info }}</span>
      </v-tooltip>
    </h1>
    <div class="py-3">
      <div class="caption mb-1">Total in date range: {{ total }} (SI: {{ totalInSLO }} - {{ sloPercent }}%) </div>
      <div class="caption mb-3" v-show="selectedCountry !== 'all'">Total for country "{{ selectedCountry }}"": {{ totalForCountry }}</div>
      <BarChart :chartdata="chartdata" :options="options" :key="barChartKey" />
      <!--div class="byCountry">
        <table class="table-auto mx-auto pa-5">
          <tbody>
            <tr v-for="(count, countryCode) in byCountryFiltered" :key="countryCode" class="border-2">
              <td :class="{ 'font-bold': countryCode === 'SI' }" class="p-2">{{ countryCode }}</td>
              <td class="p-2">{{ count }}</td>
            </tr>
          </tbody>
        </table>
      </div-->
    </div>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import BarChart from '@/components/BarChart'

export default {
  name: 'ActivatedTablets',
  components: {
    BarChart
  },
  data: () => {
    return {
      barChartKey: 0,
      info: 'Tablet becomes activated when the first user logs on it (MESI, distributor, and test users are excluded). The query for this API method counts all tablets grouped by the country which were activated in the specific time period. The MESI and distributor tablets are filtered out by excluding all users which email ends with "mesi.si" or "mesimedical.com" and by excluding all users that have distributor, dev, or admin role.',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month' // month, quarter, year
            }
          }
        }
      }
    }
  },
  computed: {
    chartlabels () {
      return this.tabletsByTime ? Object.keys(this.tabletsByTime) : []
    },
    chartcontent () {
      if (this.tabletsByTime) {
        if (this.selectedCountry !== 'all') {
          return (Object.values(this.tabletsByTime).map((obj) => {
            return obj.TabletsByCountry[this.selectedCountry]
          }))
        }
        return (Object.values(this.tabletsByTime).map(obj => obj.TotalForPeriod))
      }
      return []
    },
    chartdata () {
      return {
        labels: this.chartlabels,
        datasets: [{
          label: this.labelLegend,
          data: this.chartcontent
        }]
      }
    },
    labelLegend () {
      let label = '# of activated tablets'
      if (this.selectedCountry !== 'all') {
        label += ' in ' + this.selectedCountry
      }
      return label
    },
    tabletsByTime () {
      return this.data?.Intervals
    },
    dateFrom () {
      return this.data?.DateFrom
    },
    dateTo () {
      return this.data?.DateTo
    },
    total () {
      return this.data?.TotalForPeriod
    },
    totalForCountry () {
      if (this.selectedCountry !== 'all') {
        return this.data?.TabletsByCountry[this.selectedCountry]
      }
      return null
    },
    totalInSLO () {
      return this.data?.TabletsByCountry?.SI
    },
    sloPercent () {
      return Math.round(this.totalInSLO / this.total * 100)
    },
    ...mapState({
      dateFromTimestamp: state => state.dates.from,
      dateToTimestamp: state => state.dates.to,
      loading: state => state.loading,
      selectedCountry: state => state.selectedCountry,
      data: state => state.data.activatedTablets
    })
  },
  mounted () {
    this.getData()
  },
  updated () {
    // force re-render the BarChart component by changing it's key
    // this.barChartKey++
  },
  watch: {
    dateFromTimestamp () {
      this.getData()
    },
    dateToTimestamp () {
      this.getData()
    },
    chartdata () {
      this.barChartKey++
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('updateActivatedTablets')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
