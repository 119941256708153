<template>
<div>
  <v-select class="my-select" :value="selectedCountry" @input="updateCountry" :items="countriesFormatted" hide-details>
    <template v-slot:prepend-inner>
      <v-tooltip bottom max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-icon slot="activator" color="white" v-bind="attrs" v-on="on">mdi-earth</v-icon>
        </template>
        <span>The country for grouping in both API methods is taken from the User table. This may not be valid for all tablets since the user's country and the country where the tablet is located could be different. For more accurate statistics for countries, we would need some refactoring on database structure.</span>
      </v-tooltip>
    </template>
  </v-select>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => {
    return {
    }
  },
  computed: {
    ...mapState({
      tabletsByCountry: state => state.data.activatedTablets.TabletsByCountry,
      selectedCountry: state => state.selectedCountry
    }),
    countriesFormatted () {
      if (this.tabletsByCountry) {
        return ['all'].concat(Object.keys(this.tabletsByCountry))
      }
      return []
    }
  },
  methods: {
    updateCountry (countryCode) {
      this.$store.commit('SET_COUNTRY', countryCode)
    }
  }
}
</script>

<style lang="scss">
.my-select {
  max-width: 150px;
}
</style>
