<template>
  <v-select class="my-select" :value="chartFormat" @input="updateChart" :items="chartFormats" hide-details>
    <template v-slot:prepend-inner>
      <v-icon>mdi-chart-bar</v-icon>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => {
    return {
      chartFormats: ['Month', 'Year'] // 'Quarter'
    }
  },
  computed: {
    ...mapState({
      chartFormat: state => state.chartInterval
    })
  },
  methods: {
    updateChart (newChartVal) {
      this.$store.commit('SET_CHART', newChartVal)
      this.$store.dispatch('updateActivatedTablets')
    }
  }
}
</script>
