import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {
      activatedTablets: []
    },
    selectedCountry: 'all',
    loading: true,
    error: false,
    chartInterval: 'Month',
    dates: {
      /* // all formats are unix timestamps (seconds)
        1483225200 = 2017
        1514764861 = 2018
        1546300861 = 2019
        1577836800 = 2020
        1609459200 = 2021
        Math.floor(Date.now() / 1000),
      */
      from: '1514764861',
      to: Math.floor(Date.now() / 1000),
      lastSync: ''
    },
    activatedTablets: {},
    pairedModules: {}
  },
  mutations: {
    SET_DATE_FROM: (state, obj) => {
      const date = obj.date
      state.dates.from = moment(date).unix()
    },
    SET_DATE_TO: (state, obj) => {
      const date = obj.date
      state.dates.to = moment(date).unix()
    },
    SET_LOADING: (state, bool) => {
      state.loading = bool
    },
    SET_ERROR: (state, error) => {
      state.error = error
    },
    SET_ACTIVATED_TABLETS: (state, data) => {
      state.data.activatedTablets = data
    },
    SET_COUNTRY: (state, countryCode) => {
      state.selectedCountry = countryCode
    },
    SET_CHART: (state, type) => {
      state.chartInterval = type
    }
  },
  actions: {
    updateActivatedTablets ({ commit, state }) {
      const timestampTo = state.dates.to ? ('&timestampTo=' + state.dates.to) : ''
      const intervalType = state.chartInterval ? ('&intervalType=' + state.chartInterval) : ''
      // const apiUrl = 'https://localhost:3003/api/dashboard/stats/tablets_activated?timestampFrom=' + state.dates.from + timestampTo
      const apiUrl = 'https://mcloudstaging.azurewebsites.net/api/dashboard/stats/tablets_activated?timestampFrom=' + state.dates.from + timestampTo + intervalType

      commit('SET_LOADING', true)
      axios
        .get(apiUrl)
        .then(response => {
          commit('SET_ACTIVATED_TABLETS', response.data)
        })
        .catch(error => {
          console.log(error)
          commit('SET_ERROR', error)
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  },
  modules: {
  }
})
